@import "~react-image-gallery/styles/css/image-gallery.css";


body {
  margin: 0;
  font-family: 'montserrat', 'cursive';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'montserrat', 'cursive';
}
.image-gallery{
  height: 100%;  
  background:#fff;
}
.image-gallery div{
  height: 100%;
}
.image-gallery .image-gallery-slide > div{
  display: flex;  
}
.image-gallery-slide .image-gallery-image{
  object-fit: cover;
  border-radius: 8px;
}

.sort-button{
  min-width: 85px;
  border:1px solid transparent;
  border-radius:8px;
  padding:5px 15px;
  margin-right: 16px;
}

.sort-button.MuiButton-outlined{  
  border: 1px solid #000011;
}
.filter-actions .emotions-button{
  display: inline-flex;
  width: 170px;
}
.emotions-button .emotion-dropdown{
  padding: 0;
  max-width: 30px;
  min-width: 30px;
}
.naked-input{
  padding-bottom: 30px;  
  line-height: 28px;
  overflow: hidden;
  white-space: nowrap;
  width:90%;
  border:none;
  max-height: 58px;
  min-height: 58px;
}
.naked-input.title {
  padding-bottom: 0px;
  min-height:0px;
}
.naked-input.title input{ 
  font-size: 24px; 
  font-weight: bold;
  line-height: 28px;
}
.naked-input input{
    font-size: 18px;
    line-height: 28px;
    padding: 0;
    height: 28px;
}
.naked-input .MuiInput-underline:after,.naked-input .MuiInput-underline:before{
  display: none;
}

.MuiAutocomplete-root fieldset {
  border:none;
}